.thumbnail-news{
	img{
		@include media-breakpoint-up(md){
			max-height: calculateRem(200px);
			margin:  0 auto;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
		@include media-breakpoint-up(lg){
			max-height: calculateRem(230px);
		}
	}
}

.introduction__small{
/*	&.stuck{
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		max-width: 50%;
		&.at-end{
			position: absolute;
			bottom: 0;
			top: auto;
			max-width: 100%;
		}
	}*/
	time{
		font-size: 13px;
		letter-spacing: 1px;
		margin-bottom: 20px;
		margin-left: 20px;
		display: inline-block;
	}
	.cat-tags{
		display: inline-block;
		span{
		    font-size: 18px;
		    margin-left: 23px;
		}
	}
	.excerpt{
		font-size: 1.25rem;
	    line-height: 2rem;
	    text-align: justify;
	    @include media-breakpoint-down(md){
			font-size: 1.25rem;
		    line-height: 1.9rem;
	    }
	    @include media-breakpoint-down(sm){
			font-size: 1.15rem;
		    line-height: 1.8rem;
	    }
	}

}

.blog-container{
	.introduction__small{
		padding: 0;
		.sticky-box{
			padding-top: 70px;
			padding-bottom: 50px;
			@include media-breakpoint-up(lg){
				&.stuck{
					position: fixed;
					top: 0;
					max-width: 50%;
					&.at-end{
						position: absolute;
						top: auto;
						bottom: 0;
						max-width: 100%;
					}
				}
			}

		}
	}
}

.apply-job{
	a{
		border: 1px solid;
		font-size: 1.45rem;
		padding: 1rem 2.5rem;
		&:after{
			display: none;
		}
		&:hover{
			border-color: rgba($secondary-color,0.5);
		}
	}
}

.title-post{
	font-size: 1.5rem;
	font-weight: 500;
	@include media-breakpoint-down(md){
		display: none;
	}
}

.entry-content {
    font-size: 1.1em;
    line-height: 1.8em;
    letter-spacing: 1px;
    margin: 90px 0;
    text-align: justify;
    @include media-breakpoint-down(md){
    	margin: 40px 0;
    }
    @include media-breakpoint-down(sm){
		font-size: 1.05rem;
	    line-height: 1.7rem;
	    margin: 20px 0;
	    letter-spacing: inherit;
    }
	h1, h2, h3, h4, h5, h6{
	  margin-top: 20px;
	}
}
footer.share{
    margin: 10px 0 40px;
    @include media-breakpoint-down(md){
	    margin: 0;
	    padding: 0 15px;
	    margin-bottom: 20px;
    }
    .thumbnail{
    	position: relative;
    	margin-top: 20px;
    	margin-bottom: 20px;
   		img{
   			max-width: 350px;
   			height: auto;
   		}
    }
	h1, h2, h3, h4, h5, h6{
	  margin-top: 20px;
	  font-weight: 100;
	  font-size: 16px;
	}
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		li{
			display: inline-block;
			font-size: 1.3rem;
			margin-right: 35px;
			&:last-child{
				margin-right: 0px;
			}
			a{
				cursor: pointer;
				color: $brand-primary;
				&:after{
					display: none;
				}
				&:hover{
					color: $secondary-color !important;
				}
			}
		}
	}
}
.back_to_top {
	margin-bottom: 40px;
	font-size: 18px;
	a{
		margin-right: 20px;
		&:last-child{
			margin-right: 0;
		}
	}
}

.grid-blog{
	background-color: $beig;
	padding: 30px 0;
	position: relative;
	@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	.image__loading {
		position: absolute;
		bottom: 80px;
		left: 50%;
		@include transform(translateX(-50%));
		@include opacity(0);
	}
	.infinite-load{
		&.loading{
			padding-bottom: 200px;
			.image__loading{
				@include opacity(1);
			}
		}
	}
	.load-new{
		margin: 15px 0;

		.inner{
			background-color: $white;
			font-weight: 100;
			padding: 30px;
			position: relative;
			height: 100%;
			width: 100%;
			overflow: hidden;
			@include media-breakpoint-down(sm){
				padding: 15px;
			}
			.thumbnail{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-size: cover;
				background-position: 0% 50%;
				
				&:after{
					@include media-breakpoint-up(lg){
						content: '';
						position: absolute;
						top: 0;
						left: 45%;
						right: 0;
						bottom: 0;
						background: $white;
						@include transition(all 900ms cubic-bezier(0.19, 1, 0.22, 1));
					}

					@include media-breakpoint-down(sm){
						left: 15%;
					}
				}
			}
			&:after{
				display: none;
			}
			.header{
				display: block;
				width: 100%;
				@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
				.entry-title{
					font-weight: 100;
					font-size: 1.5rem;
					margin-bottom: 0px;
					@include media-breakpoint-down(sm){
						font-size: 1.3rem;
					}
					@include media-breakpoint-up(xl){
						font-size: 2rem;
					}
				}
				.date{
					font-size: 13px;
					@include opacity(0.75);
				}

				.cat-tags, time{
					display: inline-block;
				    margin-right: 10px;
				    font-size: 13px;
				    letter-spacing: 1px;
				    @include opacity(0.5);
				    &:last-child{
				    	margin-right: 0;
				    }
				}
			}
			.excerpt{
				margin-top: 25px;
				display: block;
				@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
			}

			.content{
				.excerpt{
					@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
				}
			}


		}

		&.dark{

			&.blue{
				.inner{
					background-color: $brand-primary;
					&:hover{
						background: darken($brand-primary,2%);	
					}
				}

			}
			&.green{

				.inner{
					background-color: $darkgreen;
					&:hover{
						background: darken($darkgreen,2%);	
					}
				}
			}
			.inner{
				color: $white;
				&:hover{
					.header, .excerpt{
						@include transform(none);
						@include opacity(1);
					}

				}		

			}
		}
	}
}
