@font-face {
    font-family: 'eden';
    src: url('../fonts/eden-45Lt.eot');
    src: url('../fonts/eden-45Lt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/eden-45Lt.woff') format('woff'),
    url('../fonts/eden-45Lt.ttf') format('truetype'),
    url('../fonts/eden-45Lt.svg#eden') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'eden';
    src: url('../fonts/eden-65Md.eot');
    src: url('../fonts/eden-65Md.eot?#iefix') format('embedded-opentype'),
    url('../fonts/eden-65Md.woff') format('woff'),
    url('../fonts/eden-65Md.ttf') format('truetype'),
    url('../fonts/eden-65Md.svg#eden') format('svg');
    font-weight: 500;
    font-style: normal;
}

*{
	text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

html,body{
	font-family: 'eden', "Helvetica Neue", sans-serif;
	font-weight: 300;
	letter-spacing: .02em;
}
body{
	overflow-x: hidden;
	color: $brand-primary;
}

a{
	color: $brand-primary;
  font-weight: 500;
  display: inline-block;
  @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
  &:after{
      content: '';
      background-color: $secondary-color;
      width: 100%;
      height: 2px;
      bottom: 3px;
      position: relative;
      display: block;
      @include opacity(0);
      @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
  }


	&:hover{
		text-decoration: none;
		color: $brand-primary;
    &:after{
      @include opacity(1);
          @include transform(translateY(3px));
    }

	}
	&:focus, &:focus:active{
		text-decoration: none;
    outline: 0;
	}
}

p{
	&:last-child{
		margin-bottom: 0;
	}
}

.boxflex{
  	display: flex;
  	flex-direction: column;
  	.row{
  		flex: 1;
  	}
}


h1, h2, h3, h4, h5, h6{
	font-weight: 500;
  margin-bottom: .9rem;
}

button{
	&:focus, &:focus:active{
		outline: 0;
	}
}

.alignleft{
	text-align: left;
}


.revealer{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10010;
}

section{
  overflow: hidden;
}

.beig__bg{
  background: $beig;
}

input {
    -webkit-border-radius:0; 
    border-radius:0;
    -webkit-appearance: none;
}