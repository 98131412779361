footer.content-info{
	overflow: hidden;
	padding: calculateRem(40px) 0;

	.extra-link{
		a{
			font-weight: 500;
		}
		@include media-breakpoint-up(lg){
			margin-top: 17px;
		}
	}

	.container{

		@include media-breakpoint-down(md){
			width: 100%;
		}
	}
	a{
		display: inline-block;
		font-weight: 100;
        &:after{
            content: '';
            background-color: $secondary-color;
            width: 100%;
            height: 2px;
            display: block;
            @include opacity(0);
            @include transform(translateY(-3px));
            @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
        }
        &:hover{
            &:after{
            	@include opacity(1);
                @include transform(translateY(0px));
            }
        }
	}
	.first-column{
		margin-top: 50px;
		@include media-breakpoint-down(sm){
			margin-top: 20px;
		}
	}
	.logo{

		img{
			max-width: 310px;
			@include media-breakpoint-down(md){
				display: none !important;
			}


		}
	}
	.contact__info{
		text-align: center;
		@include media-breakpoint-up(lg){
			text-align: right;
		}
		@include media-breakpoint-down(md){
			margin-top: 30px;
		}
		.telephone{
			font-size: 1.8rem;
			line-height: 0.85;
			display: inline-block;
			margin-left: 2rem;
			@include media-breakpoint-down(md){
				font-size: 1.7rem;
				margin:  0 2rem;

			}
			@include media-breakpoint-down(sm){
				font-size: 1.4rem;
				margin:  0 2rem;
			}
			small{
				font-weight: 300;
			    font-size: 0.95rem;
			    margin-right: 4px;
			    vertical-align: middle;
			    line-height: 1.25rem;
			}
			a{
				&:after{
					@include transform(translateY(6px));
				}
				&:hover{
					&:after{
						@include transform(translateY(9px));
					}
				}
			}
		}
		.mail{
			font-size: 2rem;
			@include media-breakpoint-down(md){
				font-size: 1.7rem;
			}
			@include media-breakpoint-down(sm){
				font-size: 1.4rem;
			}
			a{
			font-weight: 500;
			}
		}
		.address{
			font-size: 1.3em;
			@include media-breakpoint-down(md){
				font-size: 1.2em;
				margin-bottom: 10px;
			}
			@include media-breakpoint-down(sm){
				font-size: 1em;
			}
		}

		h3 {
			font-weight: 100;
			font-size: 1rem;
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
		    position: relative;
		    top: -15px;
		}
		ul.social{
			display: inline-block;
			list-style: none;
			padding: 0;
			margin: 0;
			margin-left: 7px;
			margin-bottom: 30px;
			vertical-align: middle;
			li{
				display: inline-block;
				padding: 0.3rem 0.5rem;
				a{
					font-size: 2rem;
					&:after {
						display: none;
					}
					&:hover{
						@include opacity(0.5);
					}
				}
			}
		}


	}
	.copyright{
		font-size: 13px;

		@include media-breakpoint-down(md){
			text-align: center;
		}


		a{	
			display: inline-block;
			margin-left: 10px;
			font-size: 15px;
			font-weight: 500;
		}
	}
	.block{
		.row{
			min-height: 140px;
			position: relative;
			@include media-breakpoint-down(md){
				min-height: 120px;
			}

			&:before{
			    content: '';
			    right: -50vw;
			    top: 0;
			    left: 0;
			    bottom: 0;
			    position: absolute;
			    @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}
			.inner{
				padding: 40px;
				width: 100%;
				position: relative;

				@include media-breakpoint-down(md){
					padding: 20px 15px;
				}
				.title{
					display: block;
					font-size: 18px;
				}
			}
		}
		.subscribe{
			&:before{
			    background: $dove;
			}
			form{
				position: relative;
				input[type=email]{
					width: 100%;
					background: transparent;
					border: 0;
					border-bottom: 1px solid $brand-primary;
					outline: 0;
					padding: 10px;
					color: $brand-primary;
				}
				input[type=submit]{
					display: none;
				}
				.icon{
					width: 35px;
					height: 35px;
					display: block;
					position: absolute;
					right: 0;
					top: 50%;
					@include transform(translateY(-50%));
					cursor: pointer;
					@include transform-origin(50% 50%);
					@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
					&:hover{
						@include transform(translateY(-50%) scale(0.8,0.8));
					}
				}
			}

		}
		.uploadcv{
			cursor: pointer;
			&:before{
			    background: $beig;
			}
			&:after{
				display: none;
			}
			.text{
				font-weight: 500;
				font-size: 1.65rem;
			    padding-top: 10px;
			    display: block;
			}
			.icon{
				width: 40px;
				height: 45px;
				position: absolute;
				display: block;
				right: 40px;
				top: 50%;
				@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				@include transform(translateY(-50%)  scale(0.8,0.8));
				svg{
					width: 100%;
					height: 100%;
					path{
						fill: $brand-primary;
					}
				}
			}
			&:hover{
				&:before{
					background: darken($beig,4%);
				}
				.icon{
					@include transform(translateY(-50%) scale(1,1));
				}
			}
		}
		.linkedin{
			color: $white;
			.inner{
				position: static;
			}
			&:before{
			    background: $brand-primary;
			}
			.text{
			    font-size: 1.3rem;
			    display: block;
			    position: absolute;
			    top: 50%;
			    @include transform(translateY(-50%));
			}
			.icon{
				width: 36px;
				height: 36px;
				position: absolute;
				display: block;
				right: 40px;
				top: 50%;
				@include transform(translateY(-50%) scale(0.8,0.8));
				@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				@include media-breakpoint-down(lg){
					display: none;
				}
				svg{
					width: 100%;
					height: 100%;
					path{
						fill: $white;
					}
				}
			}
			&:after{
				display: none;
			}
			&:hover{
				&:before{
					background: darken($brand-primary,2%);
				}
				.icon{
					@include transform(translateY(-50%) scale(1,1));
				}
			}
		}
	}
}

