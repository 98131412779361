.page-header{
	position: relative;
	overflow: hidden;
	&.noimg{
		.container_hero{
			.inner{
				@include gradient(to top, rgba(darken($brand-primary,3%), 0.98), $brand-primary);
			}
		}
		.overlay{
			display: none;
		}
	}
	.boxed{
		.iexplorer &{
			padding: 80px 65px 0 65px;
		}

	}
	&.news{
		.row{
			min-height: 400px;
			@include media-breakpoint-down(md){
				min-height: 320px;
				margin-top: 40px;
			}
		}
	}
	.row{
		min-height: 480px;
		@include media-breakpoint-down(md){
			min-height: 370px;
		}
		@include media-breakpoint-down(sm){
			min-height: 300px;
			margin-top: 80px;
		}
	}
	.container_hero{
		.inner{
			position: absolute;
			width: 100%;
			background-size: cover;
			background-position: 50% 50%;
			background-attachment: fixed;
		    top: 0;
		    bottom: 0;
		    @include media-breakpoint-down(lg){
		    	background-attachment: initial;
		    }
		    .iexplorer &{
		    	background-attachment: initial;
		    }
		}
	}
	.overlay{
		position: absolute;
		width: 100%;
		background: rgba(darken($brand-primary,4%),0.8);
	    top: 0;
	    bottom: 0;
	}
	.text{
		position: relative;
		color: $white;
		margin-bottom: 60px;
		margin-top: 125px;
		.iexplorer &{
			position: absolute;
			bottom: 0;
		}
		@include media-breakpoint-down(md){
			margin-top: 55px;
			margin-bottom: 40px;
		}
		@include media-breakpoint-down(sm){
			margin-bottom: 20px;
		}
		h2{
			font-weight: 500;
			font-size: 18px;
		}
		h1{
			font-weight: 100;
			font-size: 2.1rem;
			@include media-breakpoint-down(md){
		    	font-size: 1.9rem;
		    }
			@include media-breakpoint-down(sm){
		    	font-size: 1.8rem;
		    }
		}
		.inner{
		    @include media-breakpoint-down(md){
		    	padding: 0;
		    }
		}
	}

}
.description-page{
	font-size: 1.15rem;
    max-width: 600px;
    margin-top: 30px;
    text-align: justify;
	@include media-breakpoint-down(sm){
		font-size: 1.05rem;
		margin-top: 16px;
	}
}
.boxed{
	padding: 0 65px;
	@include media-breakpoint-down(md){
		padding: 0;
	}
	@include media-breakpoint-down(sm){
		padding: 0;
	}
}


// THE TEAM
.twocols{

	&.meet{
		margin-top: 50px;
		@include media-breakpoint-down(md){
			margin-top: 0;
			margin-bottom: 0;
		}
		.content{
			font-size: 1.2em;
			line-height: 1.4em;

		}
	}
}
.teammember{
	text-align: left;
	max-width: 1200px;
	font-size: 1.05em;
	@include media-breakpoint-down(md){
		text-align: center;
	}
	.remodal-close{
		margin-bottom: 32px;
	}
	img{
		max-width: 100%;
		height: auto;
		@include media-breakpoint-down(md){
			max-width: 300px;
			width: 100%;
		}
	}
	h4{
	    font-weight: 100;
	    font-size: 2.3rem;
	    margin-bottom: 5px;
		@include media-breakpoint-down(md){
			margin-top: 20px;
			font-size: 2rem;
		}
		@include media-breakpoint-down(sm){
			font-size: 1.8rem;
		}
	}
	.position{
		margin-bottom: 10px;
		font-weight: 500;
		letter-spacing: 1px;
	}
	ul.social{
		margin-top: 18px;
	    margin-bottom: 10px;
	    list-style: none;
	    padding-left: 0;
	    li{
	    	display: inline-block;
	    	font-size: 1.2rem;
	    	margin-right: 20px;
	    	a{
	    		color: rgba($white,0.9);
	    		&:after{
	    			display: none;
	    		}
	    		&:hover{
	    			color: $secondary-color;
	    		}
	    	}
	    	&:last-child{
	    		margin-right: 0;
	    	}
	    }
	}
	.content{
		display: block;
		margin-top: 10px;
		font-weight: 100;
	    line-height: 1.6rem;
	    font-size: 0.92em;
	    text-align: justify;
	}

}
.grid_team{
	.member{
		padding-left: 0;
		padding-right: 0;
		&.on-sector{
			position: relative;
			min-height: 600px;

			.img{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-size: cover;
				background-position: 50% 50%;
			}

			@include media-breakpoint-down(sm){
				min-height: 400px;
			}
			@include media-breakpoint-down(xs){
				min-height: 250px;
			}
			@include media-breakpoint-up(lg){
				max-width: 350px;
				min-height: 3px;
			}
		}
		&:after{
			display: none;
		}
		img{
			width: 100%;
			height: auto;
		}
		.overlay{
			position: absolute;
			margin-left: 0;
			margin-right: 0;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba($white,0.78);
			@include opacity(0);
			pointer-events: none;
			@include transition(all 600ms cubic-bezier(0.19, 1, 0.22, 1));
			@include media-breakpoint-down(sm){
				@include opacity(0);
			}
			.inner{
				padding: 40px;
				position: initial;
				.background{
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
				h6{
					font-weight: 100;
					font-size: 2rem;
					margin-bottom: 2px;
				}
				.position{
					font-weight: 500;
				}
				ul.social{
				    position: absolute;
				    top: 25px;
				    right: 25px;
				    margin-bottom: 0;
				    list-style: none;
				    padding-left: 0;
				    z-index: 9;
				    li{
				    	display: inline-block;
				    	font-size: 1.2rem;
				    	margin-right: 20px;
				    	a{
				    		color: rgba($brand-primary,0.9);
				    		&:after{
				    			display: none;
				    		}
				    		&:hover{
				    			color: $secondary-color;
				    		}
				    	}
				    	&:last-child{
				    		margin-right: 0;
				    	}
				    }
				}
			}
		}
		&:hover{
			.overlay{
				cursor: pointer;
				@include opacity(1);
				pointer-events: auto;
				@include media-breakpoint-down(sm){
					@include opacity(0);
				}
			}
		}
	}
}

// OUR APPROACH

.introduction_image{
	padding: 90px 0;
	position: relative;
	@include media-breakpoint-down(md){
		padding: 0;
	}

	.second{
	    font-size: 1.2rem;
	    line-height: 2rem;
		padding-left: 80px;
		text-align: justify;
		@media (max-resolution: 0.9999dppx){
			left: 70%;
		}
		@media (max-resolution: 0.4dppx){
			left: 85%;
		}
		@media (max-resolution: 0.25dppx){
			left: 100%;
		}
		@include media-breakpoint-down(md){
			padding: 25px 15px;

		}
		@include media-breakpoint-down(sm){
	    	font-size: 1.05rem;
	    	line-height: 1.7rem;
		}
	}
	.first{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		padding: 0;

		@include media-breakpoint-down(sm){
			position: relative;
			height: 200px;
		}

		.inner{
			background-size: cover;
			background-position: 50% 50%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		&:after{
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			bottom: 0;
			content: '';
			background: $white;
			@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		&.isNow{
			&:after{
				width: 0;
			}
		}
	}
}
.introduction__small{
	padding: 90px 0;
	text-align: justify;
	position: relative;
	@include media-breakpoint-down(md){
		padding: 40px 0;
	}
	@include media-breakpoint-down(sm){
		padding: 30px 0;
	}
	.content{
	    font-size: 1.2rem;
	    line-height: 2rem;

		@include media-breakpoint-down(sm){
		    font-size: 1.05rem;
		    line-height: 1.7rem;
		}
		@include media-breakpoint-down(md){
			padding-right: 20px;
		}
		@include media-breakpoint-down(sm){
			padding-right: 0px;
		}
		a{
			font-weight: 500;
			display: inline-block;
	        &:after{
	            content: '';
	            background-color: $secondary-color;
	            width: 100%;
	            height: 2px;
	            bottom: 3px;
	            position: relative;
	            display: block;
	            @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	        }
	        &:hover{
	        	&:after{
                	@include transform(translateY(3px));
	        	}
	        }
		}
	}
	.image{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		@include media-breakpoint-down(sm){
			display: none !important;
		}
		.inner{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-size: cover;
			background-position: 50% 50%;

		}
		&:after{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			bottom: 0;
			content: '';
			background: $beig;
			@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		&.isNow{
			&:after{
				width: 0;
			}
		}
	}
}
ul.tabs-links{
	list-style: none;
	margin-bottom: 0;
	padding-left: 10px;
	li{
		display: inline-block;
		margin-right: 30px;
		font-size: 1.3rem;
		@include media-breakpoint-down(sm){
			font-size: 1.1rem;
			line-height: 2rem;
			display: block;
		}
		&:last-child{
			margin-right: 0;
		}
		&.current, &.current-cat{
			a{
				&:after{
					@include opacity(1);
				}
			}
		}
		a, button{
			cursor: pointer;
			font-weight: 500;
			display: inline-block;
			background: transparent;
			border: 0;
			font-size: 18px;
			@include media-breakpoint-down(sm){
				display: block;
				text-align: left;
				font-size: 15px;
				line-height: 1.6;
			}
	        &:after{
	            content: '';
	            background-color: $secondary-color;
	            width: 100%;
	            height: 2px;
	            bottom: 3px;
	            position: relative;
	            display: block;
	            @include opacity(0);
	            @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	        }
	        &.mixitup-control-active, &:hover{
	        	&:after{
	        		@include opacity(1);
                	@include transform(translateY(3px));
	        	}
	        }

		}
	}
}

.sectors-blocks{
	h3{
		padding: calculateRem(45px) 0 calculateRem(35px);
		@include media-breakpoint-down(sm){
				padding: calculateRem(25px) 0 calculateRem(20px);
				font-size: 1.25rem;
		}
	}
	.block-sect{
		min-height: 300px;
		position: relative;
		overflow: hidden;
		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-position: 50% 50%;
			background-size: cover;
			z-index: 1;
			@include transition(transform 1s);
			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				background: rgba($brand-primary,0.6);
				@include transition(opacity .4s);
			}
		}
		&:after{
			display: none;
		}	
		span{
			position: relative;
			z-index: 3;
			width: 100%;
			font-size: 15px;
			color: $white;
			padding: 20px 35px;
		}
		@include media-breakpoint-up(md){
			&:hover{
				.overlay{
					@include transform(scale(1.1));
					&:after{
						@include opacity(0.1);
					}
				}
			}
		}

	}
}

.introduction{
	&.specialists{
		background: $brand-primary;
		color: $white;
		padding: calculateRem(70px) calculateRem(65px);
		@include media-breakpoint-down(lg){
			padding-left: 40px;
			padding-right: 40px;
		}
		@include media-breakpoint-down(md){
			padding: 25px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.title-our-specialists{
			font-weight: 500;
			font-size: 2rem;
			@include media-breakpoint-down(md){
				font-size: 1.5rem;
			}
		}
		.content{
			max-width: 650px;
			margin-top: 20px;

		}
	}
}
