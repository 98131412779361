.back-cases{
	margin-top: 30px;
}
.introduction{
	padding: 90px 0;
	h1,h2,h3,h4,h5,h6{
		text-align: left;
	}
	@include media-breakpoint-down(md){
		padding: 50px 0;
	}
	@include media-breakpoint-down(sm){
		padding: 30px 0;
	}
	&.small{
		padding: 50px 0;
		@include media-breakpoint-down(md){
			padding: 40px 0;
		}
		@include media-breakpoint-down(sm){
			padding: 25px 0;
		}
	}
	.excerpt{
		font-size: 2rem;
		font-weight: 100;
		&:after{
			display: block;
			width: 70px;
			height: 1px;
			background: $brand-primary;
			margin: 20px 0;
			content: '';
		}
	}
	.content{
		font-size: 1.15rem;
		line-height: 2rem;
		text-align: justify;
		@include media-breakpoint-down(sm){
			font-size: 1.05rem;
			line-height: 1.7rem;
		}
		a{
			font-weight: 500;
			display: inline-block;
	        &:after{
	            content: '';
	            background-color: $secondary-color;
	            width: 100%;
	            height: 2px;
	            bottom: 3px;
	            position: relative;
	            display: block;
	            @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	        }
	        &:hover{
	        	&:after{
                	@include transform(translateY(3px));
	        	}
	        }
		}
	}
}

.twocols{
	margin-bottom: 50px;
	@include media-breakpoint-down(sm){
		margin-bottom: 0;
	}
	.boxed{
		background: $beig;
		padding-top: 60px;
		padding-bottom: 60px;
		@include media-breakpoint-down(md){
			padding-top: 40px;
			padding-bottom: 40px;
		}
		@include media-breakpoint-down(sm){
			padding-top: 25px;
			padding-bottom: 25px;
		}		
	}

	.content{
		font-size: 1.15rem;
		line-height: 2rem;
		text-align: justify;
		display: block;
		@include media-breakpoint-down(sm){
			font-size: 1.05rem;
			line-height: 1.7rem;
		}
	}
	.col-md-6{
		&:first-child{
			h3{
				@include media-breakpoint-up(md){
					padding-right: 20px;
				}
			}
			.content{
				@include media-breakpoint-up(md){
					padding-right: 20px;
				}
			}
		}
		&:last-child{
			@include media-breakpoint-down(sm){
				padding-top: 40px;
			}
			h3{
				padding-left: 20px;
				@include media-breakpoint-down(md){
					padding-left: 0px;
				}

			}
			.content{
				padding-left: 20px;
				@include media-breakpoint-down(md){
					padding-left: 0px;
				}
			}
		}
	}
}

.resultcol{
	background: $brand-primary;
	color: $white;
	padding: 100px 0;
	position: relative;
	text-align: justify;
	h3{
		font-weight: 500;
		font-size: 1.4rem;
		margin-bottom: 20px;
	}
	.col-md-6{
		&:first-child{
			.content{
				padding-right: 60px;
			}
		}
	}
	.second{
		@include media-breakpoint-up(sm){
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			.inner{
				@include media-breakpoint-up(sm){
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background-position: 50% 50%;
					background-size: cover;
				}
			}
		}
	}
}


.getin_touch{
	font-size: 1.6rem;
	text-align: center;
	background: $brand-primary;
	color: $white;
	a{
		color: $white;
	}
	@include media-breakpoint-down(md){
	    font-size: 1.4rem;
	}
	@include media-breakpoint-down(sm){
	    font-size: 1.3rem;
	}
	span{
		font-weight: 500;
	}
}


.casestudy_list{
	position: relative;
	a{
		font-weight: 100;
		display: inherit;
		position: relative;
		display: block;
		&:after{
			display: none;
		}
	}

	.first{
		color: $white;
		min-height: 400px;
		padding: 40px;
		@include media-breakpoint-down(md){
			min-height: 350px;
		}
		@include media-breakpoint-down(sm){
			padding: 30px;
			min-height: 250px;
		}
		&:before{
			background: $brand-primary;
			content: '';
			left: -50vw;
			right: 0;
			top: 0;
			bottom: 0;
			position: absolute;
		}

		h4{
			font-size: 2.5rem;
			margin-bottom: 4px;
			font-weight: 100;
			@include media-breakpoint-down(sm){
					font-size: 1.4rem;
			}
		}
		.excerpt{
			font-size: 18px;
			font-weight: 100;
			@include media-breakpoint-down(sm){
				font-size: 1rem;
			}

		}
		.excerpt-intro{
			margin-top: 15px;
			@include media-breakpoint-down(sm){
				font-size: 0.85rem;
			}

		}
		.readmore{
			margin-top: 15px;
			.text{
				display: inline-block;
				font-size: 14px;
				font-weight: 500;
				margin-right: 10px;
				@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			}

		}

	}
	.second{
		background: $brand-primary;
		overflow: hidden;
		padding: 0 0;
		@include media-breakpoint-up(sm){
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
		}
		&:after{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 99;
			bottom: 0;
			width: 100%;
			background: $brand-primary;
			content: '';
			@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
		}

		.inner{
			background-size: cover;
			background-position: 50% 50%;
			@include transition(all 500ms ease-out);
			@include media-breakpoint-up(sm){
				position: absolute !important;
				top: 0 !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
			}
		}

		&.isNow{
			&:after{
				width: 0%;
			}
		}


	}
	a:hover{
		.readmore{
			.text{
				margin-left: 10px;
			}

		}
		.second{
			.inner{
				@include transform(scale(1.05,1.05));
			}
		}
	}
	&.right{
		.second{
			background: $brand-primary;
			@include media-breakpoint-up(sm){
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				padding: 0 0;
			}
			&:after{
				background: $white;
				left: auto;
				right: 0;
			}

		}
		.first{
			color: $brand-primary;
			.readmore{
				svg{
					path{
						fill: $brand-primary;
					}
				}
			}
			&:before{
				left: 0;
			    right: -50vw;
			    background-color: $white;
			}
		}
	}


}
.quote{
	position: relative;
	line-height: 1.8;
	border: 2px solid $beig;
	padding: 30px;
	@include media-breakpoint-up(md){
		margin-left: 50px;
	}
	@include media-breakpoint-down(md){
		margin-left: 0px;
		margin-top: 50px;
	}
	@include media-breakpoint-down(sm){
		padding: 15px;
	}
	svg{
		position: absolute;
		z-index: 0;
		fill: $brand-primary;
		width: 40px;
		top: 0;
		left: 0;
		@include transform(translate(-50%,-50%));

		@include media-breakpoint-down(sm){
			position: relative;
			@include transform(none);
		}
		&:last-of-type{
			bottom: -10px;
			right: 0;
			top: auto;
			left: auto;
			@include transform(translate(-50%,-50%) rotate(180deg));
			@include media-breakpoint-down(sm){
				position: relative;
				bottom: 0;
				float: right;
				@include transform(translate(0,0) rotate(180deg));
			}


		}

	}

	p, strong{
		position: relative;
	}
	strong{
		margin-top: 15px;
		display: block;
	}
}

.image-sectors{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
