@import "../../../bower_components/bootstrap/scss/functions";


// Grid settings
$enable-flex:           true;
$enable-transitions:    true;
$enable-shadows:        true;
$main-sm-columns:       12;
$sidebar-sm-columns:    3;


$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 1170px,
  xl: 1540px
);

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 850px,
  lg: 1200px,
  xl: 1600px
);

// Colors
$brand-primary: #003b4a;
$secondary-color: #5d9e37;
$darkgreen: #004e35;
$beig: #f5f1eb;
$warmgray: #cdc3bb;
$dove: #e7ebea;
$white: #fff;




// FONT SIZES

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}




$image-path: '../images' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url('#{$image-path}/#{$name}.svg');
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url('#{$image-path}/#{$name}.#{$fallback-extension}');

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url('#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension}');
        }
    }
}




@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}


@mixin transform-origin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}



// BACKGROUND
@mixin gradient($args...) {
  background-image: -webkit-linear-gradient($args); 
  background-image:    -moz-linear-gradient($args); 
  background-image:     -ms-linear-gradient($args); 
  background-image:      -o-linear-gradient($args); 
  background-image:         linear-gradient($args);
}

// OPACITY

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// CLEARFIX

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// usage: @extend %clearfix;

// Visually hide an element

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

// usage @extend %visuallyhidden;
