.hero__main{
	background: $brand-primary;
	position: relative;
	z-index: 0;
	.slider-container{
		width: 100%;
		overflow: hidden;
		position: relative;
		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			bottom: -6px;
			right: 0;
			display: block;
			background: rgba($brand-primary,0.7);
		}
	}
	.slider__home{
		width: 100%;
		overflow: hidden;
		position: relative;
		.img{

			position: relative;
			height: calc(100vh - 157px);
			min-height: 500px;
			@include media-breakpoint-down(md){
				min-height: 400px;
			}
			.inner{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				bottom: -6px;
				background-size: cover;
				background-position: 50% 50%;
				background-attachment: fixed;
			    @include media-breakpoint-down(lg){
			    	background-attachment: initial;
			    }
			    .iexplorer &{
			    	background-attachment: initial;
			    }
			}
			.text{
				position: absolute;
				z-index: 3;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%,-50%));
				color: $white;
				font-size: 1.8rem; 
				margin-top: 2vh;
				@include opacity(0);
				@include transition(opacity 600ms .2s ease-in);

		
				@include media-breakpoint-down(md){
					font-size: 1.6rem; 
				}
				@include media-breakpoint-down(sm){
					font-size: 1.4rem; 
					width: 100%;
					text-align: center;
				}
				span.contact_t{
					font-weight: 500;
					font-size: 13px;
					display: block;
				}
				span.telephone{
					display: block;
					line-height: 17px;
					a{
						color: $white;
						font-size: 23px;
						display: inline-block;
						font-weight: 100;
				        &:after{
				            content: '';
				            background-color: $secondary-color;
				            width: 100%;
				            height: 2px;
				            display: block;
				            @include opacity(0);
				            @include transform(translateY(3px));
				            @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				        }
				        &:hover{
				            &:after{
				            	@include opacity(1);
				                @include transform(translateY(6px));
				            }
				        }


					}
				}
			}

		}
		.slick-active{
			.img{
				.text{
					@include opacity(1);
				}
			}

		}
	}


	.navigation{
		@media (max-height: 500px){
			display: none;
		}
		.arr{
			width: 50px;
			height: 50px;
			display: block;
			position: absolute;
		    top: 50%;
		    z-index: 9999;
		    cursor: pointer;
		    @include transform(translateY(-50%));
		    @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		    &.arrow_left{
		    	left: 20px;
		    	@include transform(translateY(-50%) rotate(180deg));

				@include media-breakpoint-down(md){
					bottom: 0;
					top: auto;
					@include transform(translateY(0) rotate(180deg));
				}
		    	&:hover{
		    		left: 25px;
		    	}
		    }
		    &.arrow_right{
		    	right: 20px;
				@include media-breakpoint-down(md){
					bottom: 0;
					top: auto;
					@include transform(translateY(0));
				}
		    	&:hover{
		    		right: 25px;
		    	}
		    }




		}	
	}
}

.ctas{
	a{
		font-weight: 100;
		&:after{
			display: none;
		}
	}
	.first{
		&:before{
			top: 0;
			left: -50vw;
			bottom: 0;
			right: 0;
			content: '';
			background: $darkgreen;
			position: absolute;
			z-index: 0;
		}
		&:hover{
			&:before{
				background: darken($darkgreen,2%);
			}
		}
	}
	.second{
		cursor: pointer;
		&:before{
			top: 0;
			left: 0vw;
			bottom: 0;
			right: -50vw;
			content: '';
			background: $brand-primary;
			position: absolute;
			z-index: 0;
		}
		&:hover{
			&:before{
				background: darken($brand-primary,2%);
			}
		}
	}
	.icon{
		position: absolute;
		z-index: 1;
		top: 15px;
		width: 25px;
		height: 25px;
		@include opacity(0.7);
		@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		@include transform-origin(0 0);
	}
	.inner{
		color: $white;
		padding: 40px;
		z-index: 1;
		position: relative;
		top: 5px;
		@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		@include media-breakpoint-down(md){
			padding: 30px 40px;
		}
		@include media-breakpoint-down(sm){
			padding: 20px 40px;
		}
		.title{
			font-weight: 500;
			display: block;
		}
		.content{
			font-size: 2rem;
			height: calculateRem(42px);
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			white-space: nowrap;
			@include media-breakpoint-down(md){
				font-size: 1.7rem;
			}
			@include media-breakpoint-down(sm){
				font-size: 1.5rem;
			}
		}

	}
	.first, .second{
		position: relative;
		&:before{
			@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		&:hover{
			.icon{
				@include transform(scale(1.25,1.25));
			}
			.inner{
				@include transform(translateX(20px));
			}
		}
	}
}


.www__section{
	position: relative;
	a{
		font-weight: 100;
		&:after{
			display: none;
		}
	}
	.first{
		padding: 40px;
		@include media-breakpoint-down(md){
			padding: 15px;
		}
		.text{
			padding-bottom: 40px;
			padding-top: 70px;
			padding-right: 70px;
			@include media-breakpoint-down(md){
				padding-bottom: 20px;
				padding-top: 20px;
				padding-right: 30px;
			}
			@include media-breakpoint-down(sm){
				padding-bottom: 20px;
				padding-top: 20px;
				padding-right: 0px;
			}
			.content{
				font-size: 1.2rem;
			}
		}
		h3{
			font-weight: 100;
			&:after{
				display: block;
				width: 70px;
				height: 1px;
				background: $brand-primary;
				margin: 20px 0;
				content: '';
			}
		}

	}
	.second{
		padding-bottom: 40px;
		padding-top: 90px;
		@include media-breakpoint-down(md){
			padding-bottom: 20px;
			padding-top: 30px;
		}

		@include media-breakpoint-down(sm){
			padding-bottom: 0px;
			padding-top: 20px;
		}

		.slider_logos{
			text-align: center;
			img{
    			margin: 0 auto;
				margin-bottom: 20px;
    			max-width: 100%;
    			height: auto;
			}
		}
	}
	&.active{
		.second{
			@include transform(translateX(15px));
		}
	}
	
}

section.threecol__home{
	margin: 30px 0 50px;
	@include media-breakpoint-down(md){
		margin: 0 0;
	}
	.container{
		@include media-breakpoint-down(md){
			width: 100%;
		}
	}

	a{
		font-weight: 100;
		&:after{
			display: none;
		}
	}

	.boxflex{
	  	min-height: 370px;

	  	@include media-breakpoint-down(md){
	  		min-height: 270px;
	  	}
	  	@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	}
	a.first{
		background: $brand-primary;
		color: $white;
		padding: 40px;
		@include media-breakpoint-down(md){
			padding: 25px 15px;
		}
		.title{
			font-size: 2rem;
			display: inline-block;
			@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		.icon{
			display: inline-block;
			float: right;
			width: 60px;
			height: 60px;
			position: absolute;
			top: 30px;
			right: 37px;
			@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			svg{
				fill: $white;
			}
		}
		.content{
			padding-right: 30px;
			display: block;
			text-align: justify;
		}
		&:hover{
			background: darken($brand-primary,2%);	
			.title{
				padding-left: 10px;
			}
			.icon{
				margin-right: 10px;
			}
		}
	}
	a.second{
		background: $beig;
		.icon{
			display: inline-block;
		    height: 40px;
		    margin-right: 10px;
		    text-align: right;
		    position: relative;
		    top: -10px;
		    @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			svg{
				width: 70px;
				height: auto; 
				path{
					fill: $brand-primary;
				}
			}
		}
		.box{
			padding: 40px 30px;
			@include media-breakpoint-down(md){
				padding: 25px 15px;
			}

		}
		.title{
			font-size: 1.7rem;
			display: block;
			margin-bottom: 10px;
			@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		.content{
		    display: block;
		    position: relative;
		    font-weight: 500;
		    @include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			.text{
				display: inline-block;
			}

		}

		&:hover{
			.icon{
				@include transform(translateX(-10px));
			}
			.content, .title{
				padding-left: 10px;
			}
		}
	}
	a.third{
		background: $darkgreen;
		color: $white;
		padding: 40px;
		@include media-breakpoint-down(md){
			padding: 25px 15px;
		}
		.title{
			font-size: 2rem;
			display: inline-block;
			@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		.icon{
			display: inline-block;
			float: right;
			width: 80px;
			height: 80px;
			position: absolute;
			top: 30px;
			right: 30px;
			@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
			svg{
				stroke: $white;
			}
		}
		p{
			text-align: justify;
		}
		.content{
			display: block;
			font-size: 15px;
			text-align: justify;
		}
		&:hover{
			background: darken($darkgreen,2%);
			.icon{
				margin-right: 10px;
			}
			.title{
				padding-left: 10px;
			}
		}
	}
}


.caseshome__section{
	position: relative;
	.container{
		@include media-breakpoint-down(md){
			width: 100%;
		}
	}
	a{
		font-weight: 100;
		&:after{
			display: none;
		}
	}
	.first-slider{
		margin-bottom: 0;
	}
	.flex-md-top{
		margin-top: 40px;
		@include media-breakpoint-down(md){
			margin-top:20px;
		}
		h3{
			font-weight: 100;
			width: calc(100% - 150px);
			display: inline-block;
			&:after{
				display: block;
			    width: 70px;
			    height: 1px;
			    background: $white;
			    margin: 20px 0;
			    content: '';
			}
		}
		.slick-dots{
			float: right;
			position: relative;
			width: auto;
			bottom: auto;
			display: inline-block;
			margin-right: 30px;
			margin-top: 10px;
			li{
				width: 10px;
				height: 10px;
				border-radius: 999rem;
				border: 1px solid $white;
				display: inline-block;
				margin: 0 3px;
				cursor: pointer;
				&.slick-active{
					background: $white;
				}
			}
		}
	}
	.first{
		color: $white;
		min-height: 400px;
		padding: 0 40px;
		@include media-breakpoint-down(md){
			padding: 0 15px;
		}
		@include media-breakpoint-down(sm){
			min-height: 200px;
		}
		&:before{
			background: $brand-primary;
			content: '';
			left: -50vw;
			right: 0;
			top: 0;
			bottom: 0;
			position: absolute;
		}
		.item{
			padding: 40px 0;
			color: $white;
			h4{
				font-weight: 100;
				font-size: 2.5rem;
				margin-bottom: 4px;
				@include media-breakpoint-down(md){
					font-size:2.1rem;
				}
				@include media-breakpoint-down(sm){
					font-size:1.7rem;
				}
			}
			.excerpt{
				font-size: 18px;
			}
			.readmore{
				.text {
					margin-top: 15px;
					display: inline-block;
					font-size: 14px;
					font-weight: 500;
					margin-right: 10px;
					@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
				}
			}

		}
	}
	.second{
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		padding: 0 0;
		background: $brand-primary;
		@include media-breakpoint-down(sm){
			position: relative;
		}
		.slick-list{
			@include media-breakpoint-up(md){
				position: absolute !important;
				right: 0 !important;
				top: 0 !important;
				bottom: 0 !important;
				height: auto !important;
			}
		}
		&:after{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 99;
			bottom: 0;
			width: 100%;
			background: $brand-primary;
			content: '';
			@include transition(all 700ms .2s cubic-bezier(0.19, 1, 0.22, 1));
		}
		&.isNow{
			&:after{
				width: 0%;
			}
		}


		.inner, .slick-list, .slick-track{
			@include media-breakpoint-up(lg){
				position: absolute !important;
				top: 0 !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				background-size: cover;
				background-position: 50% 50%;
				@include transition(all 500ms ease-out);
			}
		}

	}
	&.active{
		.readmore{
			.text{
				margin-left: 10px;
			}
			.icon{
				@include transform(translateX(15px));
			}
		}
		.second{
			.inner{
				@include transform(scale(1.05,1.05));
			}
		}
	}
}

