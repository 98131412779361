.sticky-wrapper{
	position: absolute;
	left: 0;
	right: 0;
	z-index: 999;
}
header.banner{
	@include transition(all .5s cubic-bezier(0.19, 1, 0.22, 1));
	&.stuck{
		position: absolute;
		top: 0;
		width: 100%;
	}

	&.is-hidden{
		@include transform(translateY(-100%));
	}
	a.brand{
		padding: 10px 0;
		display: inline-block;
	    max-width: 290px;
	    width: 100%;
	    font-weight: 100;
	    position: relative;
	    z-index: 9;
	    @include media-breakpoint-down(md){
	    	max-width: 250px;
	    	padding: 10px 0 0;
	    	.logo{
	    		max-height: 60px;
	    	}
	    }
	    @include media-breakpoint-down(sm){
	    	max-width: 200px;

	    }
	    &:after{
	    	display: none;
	    }
		.logo{
			width: 100%;
			display: inline-block;
			svg{
				.iexplorer &{
					width: 100%;
					height: auto;
					max-width: 280px;
					max-height: 87px;
				}
				path{
					fill:#fff;
				}
			}
		}
	}
	&.slide{
		background: $white;
		position: fixed;
		top: 0;
		width: 100%;
		a.brand{
			.logo{
				svg{
					path{
						&.st0{
							fill: $brand-primary;
						}
						&.st1{
							fill: $secondary-color;
						}
					}
				}
			}
		}
		.hamburger .text{
			
			.is-active &{
				color: $white;
			}
		}
		.hamburger{
			.text{
				color: $brand-primary;
				@include transition(all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000));
			}
			.hamburger-inner{
				background-color: $brand-primary;
				@include transition(all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000));
				&:before, &:after{
					background-color: $brand-primary;
					@include transition(all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000));
				}

			}

			&.is-active{
				.text{
					color: $white;
					@include transition(all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000));
				}
				.hamburger-inner{
					background-color: $white;
					@include transition(all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000));
					&:before, &:after{
						@include transition(all 0.4s cubic-bezier(0.215, 0.610, 0.355, 1.000));
						background-color: $white;
					}
				}

			}
		}


	}
}




// Settings
// ==================================================
$hamburger-padding-x                       : 0px !default;
$hamburger-padding-y                       : 45px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $white !default;
$hamburger-layer-border-radius             : 0px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.75s !default;
$hamburger-hover-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1) !default;
$hamburger-hover-use-filter: false !default;






.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  z-index: 10001;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;
  top: 0;
  right: 0;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;


  @include media-breakpoint-down(lg){
  	right: 20px;
  }
  @include media-breakpoint-down(md){
  	padding: 35px 0;
  }
  @include media-breakpoint-down(sm){
  	padding: 25px 0;
  }
	&:hover{
		.text{
		margin-right: 25px;
		}
	}


	.text{
		margin-right: 20px;
		color: $white;
		letter-spacing: 0.1em;
		position: relative;
		top: -3px;
		position: relative;
		@include transition(all .4s cubic-bezier(0.19, 1, 0.22, 1));
		@include media-breakpoint-down(sm){
			display: none;
		}
		.open{
			@include opacity(1);
			@include transform(translateX(0));
			@include transition(all .4ms cubic-bezier(0.19, 1, 0.22, 1));
		}
		.close{
			position: absolute;
			left: 0;
			@include opacity(0);
			@include transform(translateX(-25px));
			@include transition(all .4ms cubic-bezier(0.19, 1, 0.22, 1));
		}
	}
  &.is-active{
  	.text{
  		.open{
			@include opacity(0);
			@include transform(translateX(15px));
  		}
  		.close{
  			@include opacity(1);
			@include transform(translateX(0));
  		}
  	}
    .hamburger-inner {
	  	border-top-right-radius: 2px;
	  	border-bottom-right-radius: 2px;
      &::before {
        transform: translate3d($hamburger-layer-width * 0.35, 2px, 0) rotate(45deg) scale(0.45, 1);
      }
      &::after {
        transform: translate3d($hamburger-layer-width * 0.35, -2px, 0) rotate(-45deg) scale(0.45, 1);
      }
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;

  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}




.nav-overlay {

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
 	background: rgba(darken($brand-primary,5%), 0.98);
	pointer-events: none;
	@include transition(all 500ms cubic-bezier(0.215, 0.610, 0.355, 1.000));
	@include opacity(0);


	.elements{
		height: calc(100% - 220px);
		margin-top: 120px;
		margin-bottom: 100px;
		@include media-breakpoint-down(md){
			margin-top: 50px;
			margin-bottom: 50px;
			height: calc(100% - 100px);
		}
		&.appear{

			ul.nav{
				li{
					$elements: 10;
					@for $i from 0 to $elements {
					  &:nth-child(#{$i}) {
						@include opacity(1);
						@include transform(translateY(0));
						@include transition(all 0.5s #{($i * 0.05)}s cubic-bezier(0.215, 0.610, 0.355, 1.000));

					  }
					}
					.iexplorer &{
						@include opacity(1);
						@include transform(none);
						@include transition(none);
					}
				}
			}
		}
		.row{
			height: 100%;
		}
		ul{
			height: 100%;
			display: flex;
			flex-direction: column;
			list-style: none;
			@include media-breakpoint-down(md){
				padding-left: 15px;
			}
			li{
				font-size: 1.5rem;
				margin: 6px 0;
				@include media-breakpoint-down(md){
					font-size: 1.15rem;
				}
				$elements: 10;
				@for $i from 0 to $elements {
				  &:nth-child(#{$i}) {
					@include opacity(0);
					@include transform(translateY(#{($i * 2)}px));
					@include transition(all .5s cubic-bezier(0.215, 0.610, 0.355, 1.000));
				  }
				}
				.iexplorer &{
					@include opacity(1);
					@include transform(none);
					@include transition(none);
				}

				a{
					color: $white;
					padding: 0;
					font-weight: 100;
					@include transition(all 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000));
					&:after{
						display: none;
					}
					&:hover{
						@include transform(translateX(5px));
						color: $secondary-color;

					}
				}


				&.active{

					a{
						@include transform(translateX(5px));
						color: $secondary-color;
					}

				}
				&.has-subpages{
					a{
						@include transform(translateX(0));
						color: $white;
						cursor: default;
 						margin-bottom: calculateRem(7px);
					}
					ul.sub-menu{
						padding-left: calculateRem(15px);
						li{
							margin: 2px 0;
						}
						.active{
							a{
								@include transform(translateX(5px));
								color: $secondary-color;
							}
						}
						a{
							font-size: 1.5rem;
							cursor: pointer;
							font-weight: 100;
							margin-bottom: 0;

							@include media-breakpoint-down(md){
								font-size: 1.15rem;
							}

							&:hover{
								@include transform(translateX(5px));
								color: $secondary-color;
							}
						}
					}
				}
			
			}
		}


	}

	.background{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 30vw;
		pointer-events: none;
		@include transform(translate(100%,100%) scale(0.5) rotate(45deg));
		@include transition(all 750ms cubic-bezier(0.215, 0.610, 0.355, 1.000));
		svg{
			@include opacity(0);
			@include transition(all 750ms cubic-bezier(0.215, 0.610, 0.355, 1.000));
			height: 100%;
			width: auto;
		}
	}

	&.opened{
	    pointer-events: auto;
	    @include opacity(1);
	    .background{
			@include transform(translate(65%,40%) scale(1.3) rotate(0));
			svg{
				@include opacity(1);
			}
	    }
	}

}

