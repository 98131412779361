// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}



// STEP FORMS


.simform {
	position: relative;
	margin: 0 auto;
	padding: 0 0 2em 0;
	max-width: 860px;
	width: 100%;
	text-align: left;
	font-size: 2.5em;
	@include media-breakpoint-down(md){
		margin-top: 30px;
	}
}

.simform .submit {
	display: none;
}

/* Question list style */
.simform ol {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	-webkit-transition: height 0.4s;
	transition: height 0.4s;
}

.simform ol:before {
	content: '';
	background-color: rgba(0,0,0,0.1);
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2.35em;
}

.questions li {
	z-index: 100;
	position: relative;
	visibility: hidden;
	height: 0;
	-webkit-transition: visibility 0s 0.4s, height 0s 0.4s;
	transition: visibility 0s 0.4s, height 0s 0.4s;
}

.questions li.current,
.no-js .questions li {
	visibility: visible;
	height: auto;
	-webkit-transition: none;
	transition: none;
}

/* Labels */
.questions li > span {
	display: block;
	overflow: hidden;
	font-size: 18px;
}

.questions li > span label {
	display: block;
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

.questions li.current > span label,
.no-js .questions li > span label {
	-webkit-transition: none;
	transition: none;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.show-next .questions li.current > span label {
	-webkit-animation: moveUpFromDown 0.4s both;
	animation: moveUpFromDown 0.4s both;
}

@-webkit-keyframes moveUpFromDown {
	from { -webkit-transform: translateY(100%); }
	to { -webkit-transform: translateY(0); }
}

@keyframes moveUpFromDown {
	from { -webkit-transform: translateY(100%); transform: translateY(100%); }
	to { -webkit-transform: translateY(0); transform: translateY(0); }
}

/* Input field */
.questions input, .questions label.file {
	display: block;
	margin: 0.3em 0 0 0;
	padding: 20px 40px 20px 28px;
	width: calc(100% - 2em);
	border: none;
	background: transparent;
	color: $white;
	font-size: 1em;
	line-height: 1;
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	overflow: hidden;
}
.questions label.file{
	padding: 20px 40px 26px 28px;
	max-height: 86px;
}
.questions .current input,
.questions .current label.file,
.no-js .questions input {
	opacity: 1;
}

.questions input:focus,
.simform button:focus {
	outline: none;
}

/* Next question button */
.next-button {
	position: absolute;
	right: 0;
	bottom: 2.3em; 
	display: block;
	padding: 0;
	width: 2em;
	height: 2em;
	border: none;
	background: none;
	text-align: center;
	opacity: 0;
	z-index: 100;
	cursor: pointer;
	@include transition(all 500ms cubic-bezier(0.19, 1, 0.22, 1));
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transform: translateX(-20%);
	transform: translateX(-20%);
	pointer-events: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	svg{
	    width: 45px;
	    height: 45px;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    @include transform(translate(-50%, -50%));
		path{
			fill: $white;
		}
	}
	&.show{
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		pointer-events: auto;
		&:hover{
			@include transform(translateX(-7px));
		}
	}

}



/* Progress bar */
.simform .progress {
	width: 0%;
	height: 0.15em;
	background: rgba($beig,1);
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
}

.simform .progress::before {
	position: absolute;
	top: auto;
	width: 100%;
	height: inherit;
	background: rgba($beig,0.05);
	content: '';
}

/* Number indicator */
.simform .number {
	position: absolute;
	right: 0;
	overflow: hidden;
	margin: 0.4em 0;
	width: 3em;
	font-size: 0.4em;
	@include media-breakpoint-down(sm){
		font-size: 16px;
	}
}

.simform .number:after {
	position: absolute;
	left: 50%;
	content: '/';
	opacity: 0.4;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.simform .number span {
	float: right;
	width: 40%;
	text-align: center;
}

.simform .number .number-current {
	float: left;
}

.simform .number-next {
	position: absolute;
	left: 0;
}

.simform.show-next .number-current {
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

.simform.show-next .number-next {
	-webkit-animation: moveUpFromDown 0.4s both;
	animation: moveUpFromDown 0.4s both;
}

/* Error and final message */
.simform .error-message,
.simform .final-message {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.4s;
	transition: opacity 0.4s;
}



.simform .error-message {
	padding: 0.4em 3.5em 0 0;
	width: 100%;
	color: rgba(0,0,0,0.7);
	font-style: italic;
	font-size: 0.4em;
}

.final-message {
	top: 50%;
	left: 0;
	padding: 0.5em;
	width: 100%;
	text-align: center;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	cursor: pointer;
}

.error-message.show,
.final-message.show {
	visibility: visible;
	opacity: 1;
}

.final-message.show {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

/* Final hiding of form / showing message */
.simform-inner.hide {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.3s, visibility 0s 0.3s;
	transition: opacity 0.3s, visibility 0s 0.3s;
}

/* No JS Fallback */
.no-js .simform {
	font-size: 1.75em;
}

.no-js .questions li {
	padding: 0 0 2em;
}

.no-js .simform .submit {
	display: block;
	float: right;
	padding: 10px 20px;
	border: none;
	background: rgba(0,0,0,0.3);
	color: rgba(0,0,0,0.4);
}

.no-js .simform .controls {
	display: none;
}

/* Remove IE clear cross */
input[type=text]::-ms-clear {
    display: none;
}



.simform{
	ul.parsley-errors-list{
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
		li{
			position: absolute;
			@include opacity(1);
			-webkit-transition: opacity 0.4s;
			transition: opacity 0.4s;
			visibility: visible;
			padding: .6em 3.5em 0 0;
			width: 100%;
			color: $beig;
			font-style: italic;
			font-size: 0.4em;
			@include media-breakpoint-down(sm){
				font-size: 15px;
			}
		}
	}
	input[type=file]{
		@include opacity(0);
		pointer-events: none;
		position: absolute;
		left: -9999vw;
	}
	label.file{
	    cursor: pointer;
	}
}



.wpcf7-submit{
	display: none !important;
	pointer-events: none;
}
